<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="2">
        <v-card>
          <v-card-title>
            <v-icon
              small
              class="mr-1"
            >
              fal fa-fw fa-file-download
            </v-icon>
            Reports
          </v-card-title>
          <v-divider />
          <v-list dense>
            <v-list-item v-if="$auth.check({ financials: 'view' })">
              <monthly-report
                title="QuickBooks Invoices"
                icon="fal fa-file-invoice"
                @action="getQuickbooks"
              >
                <template #parameters>
                  <v-col>
                    <v-text-field
                      v-model="last_invoice"
                      label="Last Invoice Number"
                    />
                  </v-col>
                </template>
              </monthly-report>
            </v-list-item>
            <v-list-item v-if="$auth.check({ financials: 'view' })">
              <monthly-report
                title="Revenue Breakdown"
                icon="fal fa-file-spreadsheet"
                xrecord="item"
                @action="getRevenue"
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            <v-icon
              small
              class="mr-1"
            >
              fal fa-fw fa-list-check
            </v-icon>
            Jobs Queue
          </v-card-title>
          <v-divider />
          <jobs-table />
        </v-card>
        <v-card class="mt-5">
          <v-card-title>
            <v-icon
              small
              class="mr-1"
            >
              fal fa-fw fa-hexagon-exclamation
            </v-icon>
            Failed Jobs
          </v-card-title>
          <v-divider />
          <failed-jobs-table />
        </v-card>
        <v-card class="mt-5">
          <v-card-title>
            <v-icon
              small
              class="mr-1"
            >
              fal fa-fw fa-book-bookmark
            </v-icon>
            Activity Log
          </v-card-title>
          <v-divider />
          <log-table />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    components: {
    //   adminDashboard: () => import('@/views/dashboards/Admin.vue'),
      LogTable: () => import('@/components/tables/LogTable.vue'),
      JobsTable: () => import('@/components/tables/JobsTable.vue'),
      FailedJobsTable: () => import('@/components/tables/FailedJobsTable.vue'),
      MonthlyReport: () => import('@/components/tables/dialogs/MonthlyReport.vue'),
    },
    data: () => ({
      last_invoice: null,
    }),
    methods: {
      getQuickbooks (item, month) {
        return this.getReport('quickbooks/csv', {
          start: this.$moment(month).startOf('month').format(),
          end:  this.$moment(month).endOf('month').format(),
          invoice_num: this.last_invoice,
        }, `QuickBooks Invoices - ${this.$moment(month).format('MMM Y')}.csv`)
      },
      getRevenue (item, month) {
        return this.getReport('invoices/xls', {
          start: this.$moment(month).startOf('month').format(),
          end:  this.$moment(month).endOf('month').format(),
        }, `Revenue Breakdown - ${this.$moment(month).format('MMM Y')}.xls`)
      },
      getReport(url, params, filename) {
        this.$toast.processing(`Generating ${filename}`)
        const toast = this.$toast.getCmp()
        this.axios({
          url: '/reports/' + url,
          params: params,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
            toast.close()
            this.$toast.success('Report generated')
          })
          .catch(error => {
            toast.close()
            this.$toast.error(error)
          })
      },
    },
  }
</script>
